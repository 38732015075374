var render = function render(){
  var _vm$history;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("연혁")]) : _vm._e(), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("영문 연혁")]), _c('v-card-text', _vm._l(_vm.historyList, function (history, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-list', [_vm._v("연혁날짜: " + _vm._s(history === null || history === void 0 ? void 0 : history.subject))])], 1), _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-list', [_c('v-btn', {
      on: {
        "click": function ($event) {
          return _vm.showDetail(history);
        }
      }
    }, [_vm._v("상세보기")])], 1)], 1), _c('v-col', [_c('v-list-item-action', [_c('v-icon', {
      on: {
        "click": function ($event) {
          return _vm.deleteHistory(history);
        }
      }
    }, [_vm._v("mdi-delete")])], 1)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-3",
    attrs: {
      "outlined": "",
      "color": "blue",
      "width": "100%"
    },
    on: {
      "click": _vm.upload
    }
  }, [_c('span', [_vm._v("연혁 추가하기")])]), _c('v-dialog', {
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.show ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1",
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v("연혁 등록")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("연도")]), _c('v-col', [_c('v-text-field', {
    model: {
      value: _vm.history.subject,
      callback: function ($$v) {
        _vm.$set(_vm.history, "subject", $$v);
      },
      expression: "history.subject"
    }
  })], 1)], 1), _c('v-divider'), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("내용")]), _c('v-col', _vm._l((_vm$history = _vm.history) === null || _vm$history === void 0 ? void 0 : _vm$history.contents, function (content, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('v-list-item', [_c('v-text-field', {
      attrs: {
        "full-width": "",
        "placeholder": "연혁 내용",
        "persistent-placeholder": ""
      },
      model: {
        value: content.detail,
        callback: function ($$v) {
          _vm.$set(content, "detail", $$v);
        },
        expression: "content.detail"
      }
    })], 1)], 1), _c('v-col', [_c('v-list-item-action', [_c('v-icon', {
      on: {
        "click": function ($event) {
          var _vm$history2;
          (_vm$history2 = _vm.history) === null || _vm$history2 === void 0 ? void 0 : _vm$history2.contents.splice(index, 1);
        }
      }
    }, [_vm._v("mdi-delete")])], 1)], 1)], 1)], 1);
  }), 1)], 1), _c('div', {
    attrs: {
      "align": "center"
    },
    on: {
      "click": _vm.addHisytory
    }
  }, [_c('v-btn', [_vm._v("추가하기")])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("등록")])], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "480"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 다음 사용자를 탈퇴처리 하시겠습니까? ")]), _c('v-card-subtitle', [_vm._v(" 탈퇴 처리 시 이전 상태로 되돌이킬 수 없습니다. ")]), _c('v-card-text', [_c('v-data-table', _vm._b({
    attrs: {
      "mobile-breakpoint": "10000",
      "disable-sort": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    })], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v(" 취소 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "red darken-1",
      "dark": ""
    },
    on: {
      "click": _vm.withdraw
    }
  }, [_vm._v(" 탈퇴 ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="px-1">
            <div class="headline text-start mt-8">
                <span v-if="user._id">회원 상세보기</span>
                <span v-else>회원 등록</span>
            </div>

            <v-card class="my-4">
                <v-row no-gutters>
                    <v-col cols="12" md="8">
                        <v-card-subtitle class="font-weight-bold grey lighten-4">계정</v-card-subtitle>
                        <v-divider />
                        <v-row class="mx-1 my-4">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="user.username" label="아이디" persistent-placeholder hide-details readonly></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <!-- <v-select v-model="user._level" label="회원등급" :items="levels" item-text="name" item-value="_id" persistent-placeholder hide-details></v-select> -->
                            </v-col>
                        </v-row>
                        <v-row class="mx-1 my-4">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="user.password" label="비밀번호" persistent-placeholder hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <!-- <v-text-field v-model="user.subPassword" label="서브비밀번호" persistent-placeholder hide-details></v-text-field> -->
                            </v-col>
                        </v-row>

                        <v-divider />
                        <v-card-subtitle class="font-weight-bold grey lighten-4">기본 정보</v-card-subtitle>
                        <v-divider />

                        <template v-if="user.type == PERSON.value">
                            <v-row class="mx-1 my-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.name" label="이름" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.bornAt" type="date" label="생년월일" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mx-1 my-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.sex" label="성별" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.nation" label="국가" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                            <v-row class="mx-1 my-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.occupation" label="직업" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.companyName" label="직장" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                        </template>
                        <template v-if="user.type == COMPANY.value">
                            <v-row class="mx-1 my-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.name" label="기업명" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.foundAt" type="date" label="기업설립일" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mx-1 my-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.businessRegisitrationNumber" label="사업자등록번호" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.nation" label="국가" persistent-placeholder hide-details />
                                </v-col>
                            </v-row>
                            <v-row class="mx-1 my-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.ceoName" label="대표자명" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field v-model="user.website" label="홈페이지주소" persistent-placeholder hide-details append-icon="mdi-link" @click:append="window.open(user.website, '_blank')" />
                                </v-col>
                            </v-row>
                        </template>

                        <v-divider />
                        <v-card-subtitle class="font-weight-bold grey lighten-4">연락처</v-card-subtitle>
                        <v-divider />

                        <v-row class="mx-1 my-4">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="user.phone" label="연락처" persistent-placeholder hide-details></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="user.email" label="이메일" persistent-placeholder hide-details></v-text-field>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row class="mx-1 my-4">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="user.postcode" label="우편번호" persistent-placeholder hide-details readonly @click="$refs.DaumPostcode.open()"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mx-1 my-4">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="user.address1" label="기본주소" persistent-placeholder hide-details readonly @click="$refs.DaumPostcode.open()"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="user.address2" label="상세주소" persistent-placeholder hide-details></v-text-field>
                            </v-col>
                        </v-row>

                        <template v-if="user.type == COMPANY.value">
                            <v-divider />
                            <v-card-subtitle class="font-weight-bold grey lighten-4">담당자 정보</v-card-subtitle>
                            <v-divider />

                            <v-row class="mx-1 my-4">
                                <v-col cols="12" sm="4" class="py-0">
                                    <v-text-field v-model="user.workerName" label="이름" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="py-0">
                                    <v-text-field v-model="user.workerPhone" label="연락처" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4" class="py-0">
                                    <v-text-field v-model="user.workerEmail" label="이메일" persistent-placeholder hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                    </v-col>
                    <v-col cols="auto">
                        <v-divider class="d-none d-md-block" vertical />
                        <v-divider class="d-block d-md-none" />
                    </v-col>
                    <v-col cols="12" md="">
                        <v-card-subtitle class="font-weight-bold grey lighten-4">정보수신방법</v-card-subtitle>
                        <v-divider />

                        <v-data-table :headers="headers_notify" :items="user.notify" disable-sort hide-default-footer mobile-breakpoint="20000">
                            <template v-for="header in headers_notify.filter((header) => header.hasOwnProperty('formatter'))" v-slot:[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                        </v-data-table>

                        <v-divider />
                        <v-card-subtitle class="font-weight-bold grey lighten-4">관리자메모</v-card-subtitle>
                        <v-divider />
                        <v-card-text>
                            <v-textarea v-if="user.type == COMPANY.value" v-model="user.note" hide-details rows="12" auto-grow class="pa-0 ma-0" />
                            <v-textarea v-if="user.type == PERSON.value" v-model="user.note" hide-details rows="7" auto-grow class="pa-0 ma-0" />
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-divider />

                <v-card-actions class="align-center grey lighten-4">
                    <v-switch v-model="user.isWarning" color="red lighten-1" dense inset hide-details class="my-0 mx-4 pa-0">
                        <span slot="label" class="subtitle-2 font-weight-bold"> 주의회원 </span>
                    </v-switch>

                    <v-spacer />
                    <v-btn color="primary" @click="save">저장</v-btn>
                    <user-withdraw v-model="user" @input="$router.go(-1)">
                        <template #activator="{attrs,on}">
                            <v-btn color="red darken-1" dark class="ml-2" v-bind="attrs" v-on="on">탈퇴</v-btn>
                        </template>
                    </user-withdraw>
                </v-card-actions>
            </v-card>

            <v-row justify="center" class="mx-1 my-4">
                <v-col md="auto" sm="auto"> </v-col>
            </v-row>
        </v-responsive>
        <daum-postcode ref="DaumPostcode" @input="setAddress"></daum-postcode>
    </v-layout>
</template>

<script>
import DaumPostcode from "@/components/plugins/daum/daum-postcode.vue";
import UserWithdraw from "@/components/console/user/user-withdraw.vue";

import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { USER_NOTIFY_METHODS, USER_TYPES } from "@/assets/variables";

const { COMPANY, PERSON } = USER_TYPES;
const notifyMethods = Object.values(USER_NOTIFY_METHODS);
const headers_notify = [
    { text: "유형", value: "method", formatter: (value) => notifyMethods.find((item) => item.value == value)?.text },
    { text: "연락처", value: "contact" },
    { text: "수신동의", value: "enabled", formatter: (value) => (value ? "동의" : "미동의") },
];

export default {
    components: {
        DaumPostcode,
        UserWithdraw,
    },
    data() {
        return {
            levels: [],
            user: {
                _id: this.$route.params._user,
            },

            window,
            PERSON,
            COMPANY,
            headers_notify,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.user._id) {
                var { user } = await api.console.users.get({ _id: this.$route.params._user });
                if (user?.bornAt) user.bornAt = user.bornAt.toDate();
                if (user?.foundAt) user.foundAt = user.foundAt.toDate();
                this.user = user;
            }

            var { levels } = await api.console.levels.gets();
            this.levels = levels;
        },

        validate() {
            try {
                // if(!this.user.name) throw new Error("담당자 이름을 입력해주세요");
                // if(!this.user.email) throw new Error("담당자 이메일을 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                if (this.user._id) {
                    await api.console.users.put({
                        ...this.user,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                } else {
                    await api.console.users.post({
                        ...this.user,
                        username: this.user.email,
                        password: this.user.password ? CryptoAES.encrypt(this.user.password) : undefined,
                    });
                }

                alert("저장되었습니다");
                this.$router.push(`/console/users`);
            }
        },

        setAddress({ postcode, address }) {
            this.user.postcode = postcode;
            this.user.address1 = address;
        },
    },
};
</script>

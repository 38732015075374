var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._board ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("게시글 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_vm.$route.params._board ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "value": (_vm.board.writer || {}).name,
      "label": "작성자",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": "",
      "disabled": ""
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-datetime-field', {
    attrs: {
      "value": _vm.board.createdAt ? _vm.board.createdAt.toDateTime() : undefined,
      "label": "작성일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    on: {
      "input": function (value) {
        return _vm.board.createdAt = value;
      }
    }
  })], 1)], 1) : _vm._e(), _vm.categories.filter(function (category) {
    return category.scope.includes(_vm.board.code);
  }).length ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.categories.filter(function (category) {
        return category.scope.includes(_vm.board.code);
      }),
      "item-text": "name",
      "item-value": "name",
      "label": "카테고리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.subject,
      callback: function ($$v) {
        _vm.$set(_vm.board, "subject", $$v);
      },
      expression: "board.subject"
    }
  })], 1), ['youtube'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('youtube-categories-view', {
    attrs: {
      "label": "카테고리",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1) : _vm._e(), ['report'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('report-view-categories', {
    attrs: {
      "label": "카테고리",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1) : _vm._e(), ['forms'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('forms-view-categories', {
    attrs: {
      "label": "카테고리",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.board.category,
      callback: function ($$v) {
        _vm.$set(_vm.board, "category", $$v);
      },
      expression: "board.category"
    }
  })], 1) : _vm._e(), ['press'].includes(_vm.board.code) ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "링크주소",
      "placeholder": "https://youtube.com",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.href,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "href", $$v);
      },
      expression: "board.meta.href"
    }
  })], 1) : _vm._e()], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "한줄설명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.summary,
      callback: function ($$v) {
        _vm.$set(_vm.board, "summary", $$v);
      },
      expression: "board.summary"
    }
  })], 1)], 1), ['event', 'store'].includes(_vm.$route.query.code || _vm.board.code) ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기간",
      "placeholder": "ex) 2020-01-01 ~ 2020.12.31",
      "hide-details": ""
    },
    model: {
      value: _vm.board.period,
      callback: function ($$v) {
        _vm.$set(_vm.board, "period", $$v);
      },
      expression: "board.period"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.board.thumb ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": _vm.createObjectURL(_vm.board.thumb),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "썸네일 이미지",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.board.thumb,
      callback: function ($$v) {
        _vm.$set(_vm.board, "thumb", $$v);
      },
      expression: "board.thumb"
    }
  })], 1)], 1)], 1)], 1), ['youtube'].includes(_vm.board.code) ? _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("유튜브")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "링크주소",
      "placeholder": "https://youtube.com/",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.youtube,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "youtube", $$v);
      },
      expression: "board.meta.youtube"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.setVideoId
    }
  }, [_vm._v("미리보기")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-card', {
    attrs: {
      "height": "176"
    }
  }, [_c('youtube', {
    staticStyle: {
      "height": "176px"
    },
    attrs: {
      "video-id": _vm.videoId,
      "fitParent": ""
    }
  })], 1)], 1)], 1) : _vm._e(), ['forms'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("파일")]), _c('v-card-text', [_c('v-file-input', {
    attrs: {
      "show-size": ""
    },
    model: {
      value: _vm.board.files[0],
      callback: function ($$v) {
        _vm.$set(_vm.board.files, 0, $$v);
      },
      expression: "board.files[0]"
    }
  })], 1)], 1) : _vm._e(), ['work'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "작가명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.authorName,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "authorName", $$v);
      },
      expression: "board.meta.authorName"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "작품유형",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.workType,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "workType", $$v);
      },
      expression: "board.meta.workType"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), ['work', 'author'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("작품보기 URL")])]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-1",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.buyEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "buyEnabled", $$v);
      },
      expression: "board.meta.buyEnabled"
    }
  })], 1)], 1)], 1), _c('v-card-text', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.board.meta.buyEnabled,
      expression: "board.meta.buyEnabled"
    }]
  }, [_c('v-text-field', {
    attrs: {
      "label": "URL",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.meta.buyURL,
      callback: function ($$v) {
        _vm.$set(_vm.board.meta, "buyURL", $$v);
      },
      expression: "board.meta.buyURL"
    }
  })], 1)], 1) : _vm._e(), ['interior-shop'].includes(_vm.$route.query.code) ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("상세정보")]), _c('v-card-text', {
    staticClass: "pb-6"
  }, [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "취급점명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.storeName,
      callback: function ($$v) {
        _vm.$set(_vm.board, "storeName", $$v);
      },
      expression: "board.storeName"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "연락처",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.storePhone,
      callback: function ($$v) {
        _vm.$set(_vm.board, "storePhone", $$v);
      },
      expression: "board.storePhone"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "이메일",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.storeEmail,
      callback: function ($$v) {
        _vm.$set(_vm.board, "storeEmail", $$v);
      },
      expression: "board.storeEmail"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.sidoGugun,
      "item-text": "name",
      "label": "시/도",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.sido,
      callback: function ($$v) {
        _vm.$set(_vm.board, "sido", $$v);
      },
      expression: "board.sido"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.board.sido ? _vm.sidoGugun.find(function (sido) {
        return sido.name == _vm.board.sido;
      }).gugun : [],
      "item-text": "name",
      "label": "구/군",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.gugun,
      callback: function ($$v) {
        _vm.$set(_vm.board, "gugun", $$v);
      },
      expression: "board.gugun"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "지역",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.area,
      callback: function ($$v) {
        _vm.$set(_vm.board, "area", $$v);
      },
      expression: "board.area"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "우편번호",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": _vm.searchPostcode
    },
    model: {
      value: _vm.board.postcode,
      callback: function ($$v) {
        _vm.$set(_vm.board, "postcode", $$v);
      },
      expression: "board.postcode"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "기본주소",
      "persistent-placeholder": "",
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": _vm.searchPostcode
    },
    model: {
      value: _vm.board.address1,
      callback: function ($$v) {
        _vm.$set(_vm.board, "address1", $$v);
      },
      expression: "board.address1"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "상세주소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.board.address2,
      callback: function ($$v) {
        _vm.$set(_vm.board, "address2", $$v);
      },
      expression: "board.address2"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), !['press'].includes(_vm.board.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("내용")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "content",
      "rows": "10"
    },
    model: {
      value: _vm.board.content,
      callback: function ($$v) {
        _vm.$set(_vm.board, "content", $$v);
      },
      expression: "board.content"
    }
  })], 1)], 1) : _vm._e(), ['interior-inquire'].includes(_vm.$route.query.code) ? _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("답변")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "id": "reply",
      "rows": "10"
    },
    model: {
      value: _vm.board.reply,
      callback: function ($$v) {
        _vm.$set(_vm.board, "reply", $$v);
      },
      expression: "board.reply"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1), _c('daum-postcode', {
    ref: "daumPostcode",
    on: {
      "change": _vm.changePostcode
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">연혁</div>
            <v-row>
                <v-col cols="12" md="12">
                    <v-card class="mt-4">
                        <v-card-title class="subtitle-1 pb-0">국문 연혁</v-card-title>
                        <v-card-text>
                            <v-list-item v-for="history, index in historyList" :key="index">
                                <v-row>
                                    <v-col cols="6">
                                        <v-list>연혁날짜: {{history?.subject}}</v-list>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-list><v-btn @click="showDetail(history)">상세보기</v-btn></v-list>
                                    </v-col>
                                    <v-col>
                                        <v-list-item-action>
                                            <v-icon @click="deleteHistory(history)">mdi-delete</v-icon>
                                        </v-list-item-action>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-btn outlined color="blue" width="100%" class="mt-3" @click="upload">
                <span>연혁 추가하기</span>
            </v-btn>

            <v-dialog v-model="show">
                <v-card v-if="show">
                    <v-card-title class="subtitle-1" primary-title>연혁 등록</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="2">연도</v-col>
                            <v-col>
                                <v-text-field v-model="history.subject"/>
                            </v-col>
                        </v-row>
                        <v-divider />
                        <v-row>
                            <v-col cols="2">내용</v-col>
                            <v-col>
                                <v-list-item v-for="content, index in history?.contents" :key="index">
                                <v-row>
                                    <v-col cols="10">
                                        <v-list-item>
                                            <v-text-field v-model="content.detail" full-width placeholder="연혁 내용" persistent-placeholder></v-text-field>
                                        </v-list-item>
                                    </v-col>
                                    <v-col>
                                        <v-list-item-action>
                                            <v-icon @click="history?.contents.splice(index, 1)">mdi-delete</v-icon>
                                        </v-list-item-action>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                            </v-col>
                        </v-row>
                        <div align="center" @click="addHisytory">
                            <v-btn>추가하기</v-btn>
                        </div>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="cancel">취소</v-btn>
                        <v-btn color="primary" text @click="save">등록</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiCloudUpload } from "@mdi/js";

export default {
    components: {
        mdiCloudUpload
    },
    data() {
        return {
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            historyList: [],
            show: false,

            history: {
                subject: null,
                contents: [{month: null, detail: null}]
            }

        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { history } = await api.console.linknet.history.gets({});
                console.log(history);
                this.ready = true;
                this.historyList = history
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                if(!!this.history._id) {
                    await api.console.linknet.history.put({_id: this.history._id, history: this.history})
                } else {
                    await api.console.linknet.history.post(this.history);
                }
                alert(this.history._id ? "수정되었습니다" : "저장되었습니다");
                this.show = false;
                this.init();
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        upload() {
            this.history = {
                subject: null,
                contents: [{month: null, detail: null}]
            }
            this.show = true;
        },
        async deleteHistory(content){
            await api.console.linknet.history.delete(content);
            alert("삭제되었습니다");
            this.init()
        },
        cancel(){
            this.show = false;
        },
        addHisytory(){
            this.history.contents = [ ... this.history.contents, { month: null, detail: null}]
        },
        showDetail(history){
            this.history = history
            this.show = true
        }
    },
};
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-responsive', _vm._b({
    staticClass: "text-center",
    class: {
      'grey lighten-2': !_vm.createObjectURL
    },
    on: {
      "click": _vm.click
    }
  }, 'v-responsive', _vm.$attrs, false), [_vm.createObjectURL ? _c('v-img', _vm._b({
    attrs: {
      "src": _vm.createObjectURL
    }
  }, 'v-img', _vm.$attrs, false)) : _c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "accept": "image/*"
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.fileInput,
      callback: function ($$v) {
        _vm.fileInput = $$v;
      },
      expression: "fileInput"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
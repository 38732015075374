<template>
    <v-container fluid fill-height class="console-terms">
        <v-layout justify-center>
            <v-responsive max-width="720" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">비밀번호 변경</v-col>
                    <v-spacer></v-spacer>
                </v-row>

                <v-card class="mt-4">
                    <v-card-text class="py-3">
                        <v-row>
                            <v-col> <v-text-field v-model="admin.password" type="password" label="비밀번호" persistent-placeholder outlined dense hide-details /> </v-col>
                            <v-col> <v-text-field v-model="admin.passwordRepeat" type="password" label="비밀번호 확인" persistent-placeholder outlined dense hide-details /> </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text color="primary" @click="saveAdmin">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";

export default {
    data() {
        return {
            admin: {
                password: "",
                passwordRepeat: "",
            },
        };
    },
    methods: {
        async saveAdmin() {
            let { password, passwordRepeat } = this.admin;
            try {
                if (password !== passwordRepeat) throw new Error("비밀번호가 일치하지 않습니다.");

                password = CryptoAES.encrypt(password);
                await api.console.users.put({ _id: this.$store.state.payload._user, password });

                alert("비밀번호가 변경되었습니다.");
            } catch (error) {
                alert(error.message)
            }
        },
    },
};
</script>

<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="800" width="100%" height="100%" class="px-3">
            <div class="headline text-start mt-8">{{ headline }}</div>
            <v-card class="mt-6">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row v-if="$route.params._form">
                        <v-col cols="6" lg="6">
                            <v-text-field :value="form.companyName" label="회사명" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="6">
                            <v-text-field :value="form.name" label="이름" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="6">
                            <v-text-field v-model="form.phone" label="연락처" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="6">
                            <v-text-field v-model="form.email" label="이메일" persistent-placeholder dense hide-details />
                        </v-col>
                        <v-col cols="6" lg="12">
                            <v-text-field :value="form.solution" label="문의한 솔루션" persistent-placeholder dense hide-details />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-divider />
                <v-card-text>
                    <v-textarea v-model="form.content" hide-details dense auto-grow />
                </v-card-text>
            </v-card>

        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            form: {
                code: this.$route.query.code,
                subject: undefined,
                content: undefined,
                createdAt: undefined,
                name: undefined,
                phone: undefined,
                email: undefined,
                solutions: [],
            },
        };
    },
    computed: {
        headline() {
            return "폼메일 상세";
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._form) {
                    var { form } = await api.console.forms.get({ _id: this.$route.params._form });
                    this.form = form;
                }
                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
    },
};
</script>

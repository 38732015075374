<template>
    <v-layout justify-center>
        <v-responsive max-width="1200px" width="100%" height="100%" class="shop-product-view">
            <v-row class="headline text-start mt-8">
                <v-col cols="auto" class="py-0">
                    <span>{{ banner._id ? "클라이언트 이미지 상세보기" : "클라이언트 등록" }}</span>
                </v-col>
                <v-spacer />
            </v-row>

            <v-row>
                <v-col cols="12" md="8">
                    <v-card>
                        <v-card-title class="subtitle-2 font-weight-bold">슬라이드<small class="grey--text pl-1">(이미지사이즈:218*78px)</small></v-card-title>
                        <v-card-text>
                            <draggable v-model="banner.slides">
                                <transition-group>
                                    <v-list-item v-for="(slide, index) in banner.slides" :key="`${index}`">
                                        <v-list-item-action>
                                            <v-preview-input v-model="slide.image" min-width="396" min-height="196" max-width="396" max-height="196" contain></v-preview-input>
                                        </v-list-item-action>
                                        <v-list-item-action>
                                            <v-icon @click="banner.slides.splice(index, 1)">mdi-delete</v-icon>
                                        </v-list-item-action>
                                    </v-list-item>
                                </transition-group>
                            </draggable>
                        </v-card-text>
                    </v-card>

                    <v-file-btn outlined color="blue" width="100%" multiple accept="image/*" class="mt-3" @change="upload">
                        <v-icon class="mr-2">{{ mdiCloudUpload }}</v-icon>
                        <span>파트너스 이미지 추가</span>
                    </v-file-btn>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card class="pa-3">
                        <v-select :items="['공공기관/지자체', '금융', '일반기업/기타']" v-model="banner.name" label="이름" persistent-placeholder hide-details></v-select>
                    </v-card>
                    <v-card class="pa-3 mt-3">
                        <v-text-field v-model="banner.code" disabled label="코드" persistent-placeholder hide-details></v-text-field>
                    </v-card>
                </v-col>
            </v-row>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import { mdiCloudUpload } from "@mdi/js";
import draggable from "vuedraggable";

import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";
import VFileBtn from "@/components/plugins/vuetify/v-file-btn.vue";

import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        draggable,

        VPreviewInput,
        VFileBtn,

        VDateField,
    },
    data() {
        return {
            mdiCloudUpload,

            images: null,

            banner: {
                _id: null,

                name: "공공기관/지자체",
                code: "clients",
                locale: "ko",
                slides: [],
            },

            closedAt: null,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (this.$route.params._banner) {
                var { banner } = await api.console.banners.get({ _id: this.$route.params._banner });

                for (let index in banner.slides) {
                    if (banner.slides[index].image) banner.slides[index].image = await api.getResource(banner.slides[index].image);
                }

                this.banner = { ...this.banner, ...banner };
            }

            if (!this.banner.slides.length) {
                this.banner.slides = [...this.banner.slides, { image: null, url: null, meta: {} }];
            }
        },

        validate() {
            try {
                if (!this.banner.name) throw new Error("로고 카테고리를 선택해 주세요");
                if (!this.banner.code) throw new Error("로고 코드를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async save() {
            if (this.validate()) {
                let { banner } = this.banner._id ? await api.console.banners.put(this.banner) : await api.console.banners.post(this.banner);

                for (let index in this.banner.slides) {
                    if (this.banner.slides[index]) await api.console.banners.images.post(banner._id, index, this.banner.slides[index].image);
                }

                alert("저장되었습니다");
                this.$router.push(`/console/client`);
            }
        },

        upload(files) {
            this.banner.slides = [...files.map((file) => ({ image: file, url: null, meta: {} })), ...this.banner.slides];
        },
    },
};
</script>

<style>
.editor.hidden {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("회원등급")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.levelsHeaders,
      "items": _vm.levels,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.actions",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), item.removable ? _c('v-icon', {
          staticClass: "ml-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")]) : _vm._e()];
      }
    }], null, false, 3855648190)
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('v-btn', {
          attrs: {
            "bottom": "",
            "color": "accent",
            "dark": "",
            "fab": "",
            "fixed": "",
            "right": ""
          },
          on: {
            "click": _vm.create
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, false, 2079359153),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm.editItem._id ? _c('span', [_vm._v(_vm._s(_vm.editItem.name) + " 수정")]) : _c('span', [_vm._v("신규등급")])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "등급순위",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.rank,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "rank", $$v);
      },
      expression: "editItem.rank"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "등급코드",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.code,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "code", $$v);
      },
      expression: "editItem.code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "등급명칭",
      "persistent-placeholder": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "name", $$v);
      },
      expression: "editItem.name"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.save(_vm.editItem);
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }
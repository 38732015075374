var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c('v-btn', {
    attrs: {
      "value": null
    }
  }, [_vm._v("전체")]), _vm._l(_vm.categories, function (_ref) {
    var text = _ref.text,
      value = _ref.value;
    return _c('v-btn', _vm._b({
      key: value
    }, 'v-btn', {
      value
    }, false), [_vm._v(" " + _vm._s(text) + " ")]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <v-data-table v-model="selected" v-bind="{ headers, items }" item-key="id" show-select single-select disable-sort disable-filtering disable-pagination hide-default-footer :items-per-page="-1">
        <template #[`item.type`]="{item, value}">
            <v-edit-dialog>
                <div>{{ FOOTER_SNS_TYPES[value]?.text || "-" }}</div>
                <template #input>
                    <v-select v-model="item.type" :items="snsTypes" v-bind="attrs_input" class="my-3" @input="emit" />
                </template>
            </v-edit-dialog>
        </template>
        <template #[`item.href`]="{item, value}">
            <v-row no-gutters align="baseline">
                <v-col cols="auto">
                    <v-btn :disabled="!value" :href="value" target="_blank" icon text small class="justify-start" width="21" height="21">
                        <v-icon class="ml-n1 mt-n1">mdi-file-find</v-icon>
                    </v-btn>
                </v-col>
                <v-col>
                    <v-edit-dialog>
                        <div>{{ item.href || "-" }}</div>
                        <template #input>
                            <v-text-field v-model="item.href" v-bind="attrs_input" class="my-3" @input="emit" />
                        </template>
                    </v-edit-dialog>
                </v-col>
            </v-row>
        </template>
        <template #[`item.shows`]="{item, value}">
            <v-switch v-model="item.shows" inset dense hide-details class="ma-0 pa-0" @change="emit">
                <span slot="label" :class="{ 'subtitle-2': true, 'primary--text': value }">{{ value ? "보이기" : "숨기기" }}</span>
            </v-switch>
        </template>
        <template #[`item.remove`]="{item}">
            <v-btn fab text small color="red" @click="remove(item)">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </template>
        <template #footer>
            <v-divider />
            <v-row class="mx-0">
                <v-col cols="auto" class="py-1 px-3">
                    <v-btn fab text small width="32" :disabled="upDisabled" @click="upItem">
                        <v-icon>mdi-chevron-up</v-icon>
                    </v-btn>
                    <v-btn fab text small width="32" :disabled="downDisabled" @click="downItem">
                        <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto" class="py-1 px-4">
                    <v-btn fab text small color="primary" @click="add()">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </v-data-table>
</template>

<script>
import { attrs_input, FOOTER_SNS_TYPES } from "@/assets/variables";

const snsTypes = Object.values(FOOTER_SNS_TYPES);
const initSns = () => Object.keys(FOOTER_SNS_TYPES).map((type) => ({ id: Math.random(), type, href: null, shows: true }));
const headers = [
    { width: 120, value: "type", text: "분류" },
    { width: 320, value: "href", text: "링크" },
    { width: 140, value: "shows", text: "표시" },
    { width: 76, value: "remove", text: "", align: "right" },
];
export default {
    props: {
        value: { type: Array, default: initSns },
    },
    data: () => ({
        items: [],
        selected: [],

        headers,
        snsTypes,
        attrs_input,
        FOOTER_SNS_TYPES,
    }),
    computed: {
        upDisabled() {
            if ((this.items || []).length < 1 || !this.selected[0] || !this.items.some(({ id }) => id == this.selected[0]?.id)) return true;
            return this.selected?.[0]?.id == this.items?.[0]?.id;
        },
        downDisabled() {
            if ((this.items || []).length < 1 || !this.selected[0] || !this.items.some(({ id }) => id == this.selected[0]?.id)) return true;
            return [...(this.selected || [])]?.pop()?.id == [...(this.items || [])]?.pop()?.id;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        },
    },
    methods: {
        init() {
            if (this.value.length < 1) this.items = initSns();
            else this.items = this.value.map((item) => ({ id: Math.random(), ...item }));
        },
        emit() {
            this.$emit("input", this.items);
        },
        add() {
            this.items.push({ id: Math.random(), term: null, desc: null });
            this.emit();
        },
        remove({ id }) {
            this.items = this.items.filter((item) => item?.id !== id);
            this.emit();
        },
        upItem() {
            const index = (this.items || []).findIndex(({ id }) => id == this.selected[0]?.id);
            this.items.splice(index - 1, 2, this.items[index], this.items[index - 1]);
            this.emit();
        },
        downItem() {
            const index = (this.items || []).findIndex(({ id }) => id == this.selected[0]?.id);
            this.items.splice(index, 2, this.items[index + 1], this.items[index]);
            this.emit();
        },
    },
};
</script>

<style></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', _vm._b({
    attrs: {
      "item-key": "id",
      "show-select": "",
      "single-select": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([{
      key: `item.type`,
      fn: function (_ref) {
        var _vm$FOOTER_SNS_TYPES$;
        var item = _ref.item,
          value = _ref.value;
        return [_c('v-edit-dialog', {
          scopedSlots: _vm._u([{
            key: "input",
            fn: function () {
              return [_c('v-select', _vm._b({
                staticClass: "my-3",
                attrs: {
                  "items": _vm.snsTypes
                },
                on: {
                  "input": _vm.emit
                },
                model: {
                  value: item.type,
                  callback: function ($$v) {
                    _vm.$set(item, "type", $$v);
                  },
                  expression: "item.type"
                }
              }, 'v-select', _vm.attrs_input, false))];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(((_vm$FOOTER_SNS_TYPES$ = _vm.FOOTER_SNS_TYPES[value]) === null || _vm$FOOTER_SNS_TYPES$ === void 0 ? void 0 : _vm$FOOTER_SNS_TYPES$.text) || "-"))])])];
      }
    }, {
      key: `item.href`,
      fn: function (_ref2) {
        var item = _ref2.item,
          value = _ref2.value;
        return [_c('v-row', {
          attrs: {
            "no-gutters": "",
            "align": "baseline"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "justify-start",
          attrs: {
            "disabled": !value,
            "href": value,
            "target": "_blank",
            "icon": "",
            "text": "",
            "small": "",
            "width": "21",
            "height": "21"
          }
        }, [_c('v-icon', {
          staticClass: "ml-n1 mt-n1"
        }, [_vm._v("mdi-file-find")])], 1)], 1), _c('v-col', [_c('v-edit-dialog', {
          scopedSlots: _vm._u([{
            key: "input",
            fn: function () {
              return [_c('v-text-field', _vm._b({
                staticClass: "my-3",
                on: {
                  "input": _vm.emit
                },
                model: {
                  value: item.href,
                  callback: function ($$v) {
                    _vm.$set(item, "href", $$v);
                  },
                  expression: "item.href"
                }
              }, 'v-text-field', _vm.attrs_input, false))];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(_vm._s(item.href || "-"))])])], 1)], 1)];
      }
    }, {
      key: `item.shows`,
      fn: function (_ref3) {
        var item = _ref3.item,
          value = _ref3.value;
        return [_c('v-switch', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "inset": "",
            "dense": "",
            "hide-details": ""
          },
          on: {
            "change": _vm.emit
          },
          model: {
            value: item.shows,
            callback: function ($$v) {
              _vm.$set(item, "shows", $$v);
            },
            expression: "item.shows"
          }
        }, [_c('span', {
          class: {
            'subtitle-2': true,
            'primary--text': value
          },
          attrs: {
            "slot": "label"
          },
          slot: "label"
        }, [_vm._v(_vm._s(value ? "보이기" : "숨기기"))])])];
      }
    }, {
      key: `item.remove`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "fab": "",
            "text": "",
            "small": "",
            "color": "red"
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-minus")])], 1)];
      }
    }, {
      key: "footer",
      fn: function () {
        return [_c('v-divider'), _c('v-row', {
          staticClass: "mx-0"
        }, [_c('v-col', {
          staticClass: "py-1 px-3",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "text": "",
            "small": "",
            "width": "32",
            "disabled": _vm.upDisabled
          },
          on: {
            "click": _vm.upItem
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1), _c('v-btn', {
          attrs: {
            "fab": "",
            "text": "",
            "small": "",
            "width": "32",
            "disabled": _vm.downDisabled
          },
          on: {
            "click": _vm.downItem
          }
        }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1)], 1), _c('v-spacer'), _c('v-col', {
          staticClass: "py-1 px-4",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          attrs: {
            "fab": "",
            "text": "",
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function ($event) {
              return _vm.add();
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }